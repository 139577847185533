<template>
    <div class="wrap">
        <van-nav-bar
        :title="title"
        @click-left="clickNavBack"
        left-arrow
        id="reset"
        :border="false"
        ></van-nav-bar>
        <div>
        <div class="page-body">
            <div class="content">
                <div class="section-title">
                    <span class="status"><span class="title-style">{{ examTitles[0] ? questionType[examTitles[0].questionType] : "" }}</span></span>
                    <span class="title-txt">{{ examTitles[0] ? examTitles[0].questionStem : "-" }}</span>
                </div>
                <div v-if="examTitles[0] && examTitles[0].questionPic" class="section-img">
                    <img :src="examTitles[0].questionPic" alt="img" />
                </div>
                <div v-if="examTitles[0]" class="section-options">
                    <van-radio-group v-model="checked" v-if="examTitles[0].questionType !== 2" :disabled="rightAndYour[testingIdx].u === '' ? false : true ">
                        <van-radio
                            v-for="(item,index) in examTitles[0].answers"
                            :key="item.basePkId"
                            :name="index"
                            checked-color="#2db1ac"
                            :class="['option-item', { active: item.select }]"
                            @click="chooseAnswer($event)"
                        >{{ 'AABCDEFGHIG'[item.answerNo] }}. {{ item.answerText }}</van-radio>
                    </van-radio-group>
                    <van-checkbox-group v-if="examTitles[0].questionType === 2" v-model="multiSelect" :disabled="eventBtn">
                        <van-checkbox
                            v-for="(item,index) in examTitles[0].answers"
                            :name="index"
                            :key="item.basePkId"
                            :class="['option-item', { active: item.select }]"
                            checked-color="#2db1ac"
                            @click="eventBtn && chooseAnswer($event)"
                        >{{ 'AABCDEFGHIG'[item.answerNo] }}. {{ item.answerText }}</van-checkbox>
                    </van-checkbox-group>
                    <van-button
                        v-if="examTitles[0].questionType === 2"
                        block
                        round
                        type="primary"
                        class="determine"
                        @click.native="testings"
                        :disabled="eventBtn"
                    >确认答案</van-button>
                </div>
                <div class="btn-wrap">
                    <img @click="prev" class="btn default" src="../../assets/icon/last.png"/>
                    <img @click="next" class="btn" src="../../assets/icon/next.png"/>
                </div>
                <div class="section-desc">
                    <div @click="confirmSubimit" class="left" v-if="examinationManageEntity">
                        <img :src="require('../../assets/icon/icon-page'+(appno==1 ? '' : appno)+'.png')" alt="icon" />
                        <span>交卷</span>
                    </div>
                    <div @click="showPopup" class="right-wrap">
                        <div class="right right-answer">
                            <img :src="require('../../assets/icon/icon-right'+(appno==1?'':appno)+'.png')" alt="icon" />
                            <span>{{ rightCount }}</span>
                        </div>
                        <div class="right wrong-answer">
                            <img src="@/assets/icon/icon-wrong.png" alt="icon" />
                            <span>{{ wrongCount }}</span>
                        </div>
                        <div class="right">
                            <van-icon style="margin-right: 6px; color: #000" name="apps-o" size="20" />
                            <span>{{ testingIdx + 1 }} / {{ ExercisesId.length }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-wrap" v-if="errorprompt">
            <div :class="rightAndYour[testingIdx].check===false?'wrong':'iswrong'">{{ rightAndYour[testingIdx].check===false ? '回答错误' : '回答正确' }}</div>
            <div class="right-key">你选择:
                <span class="seen">{{ rightAndYour[testingIdx].u }}</span>
            </div>
            <div class="right-key">正确答案:
                <span v-for="(item, index) in examTitles[0].answers" :key="index">
                    <span :class="item.ifCorrenct==='0' ? 'seen' : 'unseen'">{{ index | getLetter }}</span>
                </span>
            </div>
            <div class="problem">本题技巧
                <div class="tip">{{ examTitles[0].questionAnalyse }}</div>
            </div>
            <div class="explain-detai">试题详解</div>
            <div class="topic-analysis">题目解析
                <div class="tip">{{ examTitles[0].questionAnalyse }}</div>
                <div class="difficulty" v-if="examTitles[0].questionDifficulty===1">简单</div>
                <div class="difficulty" v-else-if="examTitles[0].questionDifficulty===2">一般</div>
                <div class="difficulty" v-else>困难</div>
            </div>
        </div>
        <van-popup
            v-model="titlePopup"
            position="bottom"
            :style="{ height: '40%' }"
        >
            <div class="popup-wrap">
                <div class="section-desc">
                    <div @click="confirmSubimit" class="left" v-if="examinationManageEntity">
                        <img :src="require('../../assets/icon/icon-page'+(appno==1 ? '' : appno)+'.png')" alt="icon">
                        <span>交卷</span>
                    </div>
                    <div class="right-wrap">
                        <div class="right right-answer">
                            <img :src="require('../../assets/icon/icon-right'+(appno==1 ? '' : appno)+'.png')" alt="icon">
                            <span>{{ rightCount }}</span>
                        </div>
                        <div class="right wrong-answer">
                            <img src="@/assets/icon/icon-wrong.png" alt="icon">
                            <span>{{ wrongCount }}</span>
                        </div>
                        <div class="right">
                            <van-icon style="margin-right:6px" name="apps-o" size="20" />
                            <span>{{ testingIdx + 1 }} / {{ ExercisesId.length }}</span>
                        </div>
                    </div>
                </div>
                <div class="section-cicle" v-if="rightAndYour[testingIdx] !== undefined">
                    <span v-for="(item,index) in ExercisesId.length" @click="gotitle(index)" :key="index" :class="[`cicle-index ${ rightAndYour[index].u !== '' ? rightAndYour[index].check === true ? 'cicle-correct' : 'cicle-error':'' }`, { active:index == testingIdx }]">
                    {{ index + 1 }}
                    </span>
                </div>
            </div>
        </van-popup>
        <van-popup round v-model="showPop">
            <div class="popup-content confirm">
                <div class="content">
                    确定交卷吗?
                </div>
                <div class="btn-wrap">
                    <van-button @click="cancle" style="color: #2db1ac;border: 1px solid #2db1ac;" class="btn default" type="primary">取消</van-button>
                    <van-button @click="confirm" style="background: linear-gradient(90deg, #2db1ac, #2db1ac);border: 1px solid #2db1ac;" class="btn" type="primary">确定</van-button>
                </div>
            </div>
        </van-popup>
        <van-popup round v-model="scores" v-if="examinationManageEntity">
            <div class="popup-content">
                <div class="content">
                    满分{{ examinationManageEntity.examinationTotal }},合格分{{ examinationManageEntity.examinationPass }}<br>本次考试得分{{ score }}分<br>本次考试{{ score >= examinationManageEntity.examinationPass ? '合格' : '不合格' }}
                </div>
                <div class="btns-wrap" style="padding:0 0 25px 50px">
                    <van-button @click="clickNavBack" style="background: linear-gradient(90deg, #2db1ac, #2db1ac);border: 1px solid #2db1ac;" class="btn" type="primary">确定</van-button>
                </div>
            </div>
        </van-popup>
        </div>
    </div>
</template>

<script>
import { getQuestion } from '../../api/user';
export default {
    name: 'vipsecret',
    data() {
        return {
            title: '',
            testingIdx: 0,
            examTitles: [],
            questions: {},
            answers: [],
            rightAndYour: [],
            questionType: {
                1: "单选题",
                2: "多选题",
                3: "判断题",
            },
            examinationId: 0,
            score:0,
            num:0,
            isopen: true,
            showPop: false,
            titlePopup: false,
            errorprompt: false,
            scores: false,
            eventBtn: false,
            isflag: false,
            timer: null,
            rightCount:0,
            wrongCount:0,
            stindex: false,
            checked: -1,
            multiSelect: [],
        }
    },
    components: {
    },
    created() {
        this.subject = this.$route.query.subject;
        this.getQuestion();
        this.title = this.$route.query.title;
    },
    computed: {
        appno() {
            return this.$store.state.app.appno
        },
        ExercisesId() {
            return JSON.parse(sessionStorage.getItem('exam')).ExercisesId
        },
        examinationManageEntity() {
            return JSON.parse(sessionStorage.getItem('exam')).examinationManageEntity
        },
        rightAnswer() {
            const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            let ans = '';
            this.answers.map((v,i) => {
                if (v.ifCorrenct==0) {
                    ans += letters.charAt(i);
                }
            })
            return ans;
        },
        yourAnswer: {
            get(){
                const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                let ans = '';
                this.answers.map((v,i) => {
                    if (v.select) {
                        ans += letters.charAt(i);
                    }
                })
                return ans;
            },
            set(val){
                return val;
            }
        }
    },
    mounted() {
        this.num = this.ExercisesId.length
        for (var i = 0; i < this.num; i++) {
            var add = {
                r: this.rightAnswer,
                u: this.yourAnswer,
                check: '',
                t: '',
                id: -1,
            };
            this.rightAndYour.push(add);
        }
        if (this.examinationManageEntity) {
            this.countDown();   
        }
    },
    methods: {
        // 获取练习题
        getQuestion() {
            getQuestion({
                'questionId[0]':this.ExercisesId[this.testingIdx]
            }).then((data) => {
                this.examTitles = data;
                this.examTitles[0].answers.find((v,i) => {
                    if ('AABCDEFGHIG'[v.answerNo] === this.rightAndYour[this.testingIdx].u) {
                        this.stindex = i;
                    }
                })
                if (this.examTitles[0].questionType === 2 && this.rightAndYour[this.testingIdx].check !== '') {
                    this.eventBtn = true;
                } else {
                    this.eventBtn = false;
                }
                this.examTitles[0].questionType === 2 ? this.rightAndYour[this.testingIdx].id === -1 ? this.multiSelect = [] : this.multiSelect = this.rightAndYour[this.testingIdx].id : this.checked = this.rightAndYour[this.testingIdx].id;
            }).catch(() => {});
        },
        // 点击回退
        clickNavBack() {
            this.$router.go(-1);
        },
        // 多选题提交
        testings() {
            if (this.testingIdx > this.ExercisesId.length - 1) {
                this.$dialog.alert({
                    message: "已经是最后一题了",
                    theme: "round-button",
                    confirmButtonColor: "#2db1ac",
                    width: 290,
                });
                return;
            }
            if (this.multiSelect.length < 2 && this.examTitles[0].questionType === 2) {
                this.$dialog.alert({
                    message: "请至少选择2个选项",
                    theme: "round-button",
                    confirmButtonColor: "#2db1ac",
                    width: 290,
                });
                return;
            }
            this.eventBtn = true;
            this.chooseAnswer()
        },
        // 选择选项
        chooseAnswer() {
            if (this.testingIdx > this.ExercisesId.length - 1) {
                this.$dialog.alert({
                    message: "已经是最后一题了",
                    theme: "round-button",
                    confirmButtonColor: "#2db1ac",
                    width: 290,
                });
                return;
            }
            // 选择答案
            let answers = this.examTitles[0].answers;
            let type = this.examTitles[0].questionType;
            answers.map((v, i) => {
                let flag = !!v.select;
                if (type == 2) {
                    // 多选
                    this.multiSelect.map(val => {
                        if (i === val) {
                            v.select = !flag
                        }
                    })
                } else {
                    // 单选 判断
                    if (i === this.checked) {
                        v.select = !flag;
                    } else {
                        v.select = false;
                    }
                }
            });
            this.examTitles[0].answers = answers;
            this.answers = answers;
            this.testing();
        },
        // 判断选项
        testing() {
            const answers = this.examTitles[0].answers;
            const answer = answers.find((v) => v.select);
            if (answer === undefined) {
                this.yourAnswer = '';
            }
            var add = {
                r: this.rightAnswer,
                u: this.yourAnswer,
                check: this.rightAnswer==this.yourAnswer,
                t: this.examTitles[0].questionType,
                id: this.examTitles[0].questionType === 2 ? this.multiSelect : this.checked,
            };
            if (this.rightAndYour[this.testingIdx].id === -1) {
                this.rightAndYour[this.testingIdx] = add;
            }
            this.isrightCount();
            this.iswrongCount();
            if ((this.rightAndYour[this.testingIdx].check === false || this.rightAndYour[this.testingIdx].check === true) && this.rightAndYour[this.testingIdx].u !== '') {
                this.errorprompt = true;
            }else{
                this.errorprompt = false;
            }
            console.log(this.rightAndYour);
            if (this.rightAndYour[this.testingIdx].check === true) {
                this.next();
            }
        },
        // 上一题
        prev() {
            if (this.testingIdx <= 0) {
                this.$dialog.alert({
                    message: "已经是第一题了",
                    theme: "round-button",
                    confirmButtonColor: "#2db1ac",
                    width: 290,
                });
                return;
            }
            this.testingIdx--;
            if ((this.rightAndYour[this.testingIdx].check === false || this.rightAndYour[this.testingIdx].check === true) && this.rightAndYour[this.testingIdx].u !== '') {
                this.errorprompt = true;
            } else{
                this.errorprompt = false;
            }
            this.stindex = true;
            this.getQuestion();
        },
        // 下一题
        next() {
            if (this.testingIdx >= this.ExercisesId.length - 1) {
                this.$dialog.alert({
                    message: "已经是最后一题了",
                    theme: "round-button",
                    confirmButtonColor: "#2db1ac",
                    width: 290,
                });
                return;
            }
            this.testingIdx++;
            if ((this.rightAndYour[this.testingIdx].check === false || this.rightAndYour[this.testingIdx].check === true) && this.rightAndYour[this.testingIdx].u !== '') {
                this.errorprompt = true;
            } else {
                this.errorprompt = false;
                this.stindex = true;
            }
            this.getQuestion();
        },
        // 交卷
        confirmSubimit() {
            this.showPop = true;
        },
        // 展示所有题
        showPopup() {
            this.titlePopup = true;
        },
        // 跳转到某一题
        gotitle(index) {
            this.testingIdx = index;
            if ((this.rightAndYour[index].check === false || this.rightAndYour[index].check === true) && this.rightAndYour[this.testingIdx].u !== '') {
                this.errorprompt = true;
            } else {
                this.errorprompt = false;
            }
            this.getQuestion();
        },
        // 取消交卷
        cancle() {
            this.showPop = false;
        },
        // 确定交卷
        confirm() {
            this.handleSubmit();
        },
        // 正确题数
        isrightCount() {
            let arr = this.rightAndYour.filter(v => v.check === true);
            return this.rightCount = arr.length;
        },
        // 错误题数
        iswrongCount() {
            let arr = this.rightAndYour.filter(v => v.check === false);
            return this.wrongCount = arr.length;
        },
        // 结算分数
        handleSubmit() {
            this.showPop = false;
            const scoreMap = {
                1: this.examinationManageEntity.singleChoiceScore,
                2: this.examinationManageEntity.multiChoiceScore,
                3: this.examinationManageEntity.judgeChoiceScore,
            }
            this.rightAndYour.map(v => {
                if (v.check) this.score += scoreMap[v.t];
            })
            this.scores = true;
        },
        // 倒计时
        countDown() {
            let that = this;
            let time = {
                init: function () {
                    // 小于10时补0
                    function checkTime(i) {
                        if (i < 10) {
                            i = '0' + i
                        }
                        return i;
                    }
                    if (!that.examinationManageEntity || !that.examinationManageEntity.examinationTime) return;
                    let countTime = that.examinationManageEntity.examinationTime * 60;
                    let h = Math.floor(countTime / 60 / 60);
                    let m = Math.floor(countTime / 60 % 60);
                    let s = countTime % 60;
                    that.title = checkTime(h) + ":" + checkTime(m) + ":" + checkTime(s);

                    //进行倒计时显示
                    that.timer = setInterval(function () {
                        --s;
                        if (s < 0) {
                            --m;
                            s = 59;
                        }
                        if (m < 0) {
                            --h;
                            m = 59;
                        }
                        if (h < 0) {
                            s = 0;
                            m = 0;
                            clearInterval(that.timer);
                            that.handleSubmit();
                            return false;
                        }
                        that.title = checkTime(h) + ":" + checkTime(m) + ":" + checkTime(s);
                    }, 1000);
                }
            }
            time.init();
        },
    }
}
</script>

<style lang="less" scoped>
.van-nav-bar {
    background-color: #2db1ac !important;
}
::v-deep .van-icon {
    color: #fff;
}
::v-deep .van-ellipsis {
    color: #fff;
}
.wrap{
    position: relative;
}
.secret-wrap{
    width: 100%;
    height: 100vh;
    background: url('../../assets/vip/secret-bj2.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 5%;
    .secret-topics{
        margin-top: 40vh;
        display: flex;
        justify-content: space-around;
        .secret-ques{
            width: 45%;
            height: 120px;
            margin: 30px auto;
            background-color: transparent;
            border: 2px solid red;
            position: relative;
            .ques-name{
                width: 100%;
                height: 50px;
                text-align: center;
                background-color: #ff000080;
                color: #fff;
                line-height: 20px;
                padding-top: 15px;
                font-size: 16px;
                font-weight: 800;
            }
            .ques-btn{
                text-align: center;
                margin-top: 15px;
                .ques-button{
                    width: 80px;
                    height: 30px;
                    background: linear-gradient(to bottom, #FF450080, #ff000090);
                    color: #fff;
                    animation:myfirst 2s;
                    -webkit-animation:myfirst 2s;
                    animation-iteration-count:99999;
                    -webkit-animation-iteration-count:99999;
                }
                @keyframes myfirst
                {
                    0%{transform: scale(1);box-shadow: -2px 2px 3px #00000080;}
                    100%{transform: scale(1.3);box-shadow: -2px 2px 3px #00000080;}
                }
                @-webkit-keyframes myfirst
                {
                    0%{transform: scale(1);box-shadow: -2px 2px 3px #00000080;}
                    100%{transform: scale(1.3);box-shadow: -2px 2px 3px #00000080;}
                }
            }
        }
    }
}
.page-body {
    height: 100%;
    margin-top: 0;
    padding: 20px 0 15px;
    .content {
        position: relative;
        top: -14px;
        background: #fff;
        border-radius: 14px;
        padding: 20px 15px 35px;
        .section-title {
            display: flex;
            margin-bottom: 12px;
            .status {
                flex-shrink: 0;
                margin-right: 6px;
            }
            .title-style {
                display: inline-block;
                margin-top: 2px;
                padding: 2px 6px;
                background: linear-gradient(90deg, #2db1ac, #2db1ac);
                border-radius: 17px;
                color: #fbf9f8;
                font-size: 11px;
            }
            .title-txt {
                font-size: 15px;
                color: #444444;
                line-height: 1.8;
            }
        }
        .section-img {
            margin-bottom: 10px;
            img {
                width: 100%;
            }
        }
        .section-options {
            margin-bottom: 40px;
            .option-item {
                margin-bottom: 12px;
                color: #444444;
                .letter {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    font-size: 13px;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                    margin-right: 8px;
                    border-radius: 20px;
                }
                .letters {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    font-size: 13px;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                    margin-right: 8px;
                    border-radius: 20px;
                    color: #fff;
                    background: #2db1ac;
                }
                &.active {
                    .letter {
                        color: #fff;
                        background: #2db1ac;
                    }
                }
                .letteres {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    font-size: 13px;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                    margin-right: 8px;
                    border-radius: 20px;
                    color: #fff;
                    background: #2db1ac;
                }
            }
            .van-radio{
                margin: 10px 0;
            }
        }
        .determine{
            // margin: 0 auto;
            // width: 200px;
            // height: 50px;
            // line-height: 50px;
            // text-align: center;
            // color: #fff;
            background-color: #2db1ac;
            // border-radius: 25px;
        }
        .btn-wrap {
            display: flex;
            justify-content: flex-end;
            margin: 40px 0 0;
            .btn {
                width: 70px;
                padding: 0;
                height: 30px;
                font-size: 14px;
                margin: 0 10px 0 0;
                color: #fff;
                background: linear-gradient(90deg, #2db1ac, #2db1ac);
                border-radius: 40px;
                border: 1px solid #2db1ac;
            }
            .btn .default {
                background: #ffffff;
                color: #2db1ac;
                border: 1px solid #2db1ac;
            }
        }
        .section-desc {
            display: flex;
            justify-content: space-between;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 60px;
            left: 0;
            padding: 0 20px;
            line-height: 60px;
            border-top: 1px solid #e1e1e1;
            background: #ffffff;
            z-index: 10;
            img {
                width: 20px;
                margin-right: 8px;
            }
            .left {
                display: flex;
                align-items: center;
            }
            .right-wrap {
                display: flex;
                position: absolute;
                right: 10px;
                top: 0;
            }
            .right {
                display: flex;
                align-items: center;
                margin-right: 12px;
                &.right-answer {
                color: #2db1ac;
                }
                &.wrong-answer {
                color: #ff1a1a;
                }
                .van-icon{
                color: #000;
                }
            }
        }
    }
}
.panel-wrap {
    width: 100%;
    .wrong{
        height: 50px;
        text-align: center;
        color: red;
        line-height: 50px;
        font-size: 16px;
    }
    .iswrong{
        height: 50px;
        text-align: center;
        color: #2db1ac;
        line-height: 50px;
        font-size: 16px;
    }
    .right-key{
        width: 90%;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-weight: bolder;
        margin-left: 5%;
        border-bottom: 1px solid #000;
        .seen{
            color: #2db1ac;
        }
        .unseen{
            display: none;
        }
    }
    .problem{
        line-height: 30px;
        padding-left: 5%;
        font-weight: bold;
        font-size: 14px;
        .tip{
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            padding-right: 5%;
            padding-left: 0;
        }
    }
    .explain-detai{
        width: 90%;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-weight: bolder;
        margin-left: 5%;
        border-bottom: 1px solid #000;
    }
    .video-analysis{
        line-height: 40px;
        padding-left: 5%;
        font-weight: bold;
        font-size: 14px;
        .video-wrap{
            width: 95%;
            height: 210px;
            border: 1px solid red;
        }
    }
    .topic-analysis{
        line-height: 40px;
        padding-left: 5%;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 20%;
        .tip{
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            padding-right: 5%;
            padding-left: 0;
        }
        .difficulty{
            line-height: 30px;
            color: #2db1ac;
            font-size: 14px;
            font-weight: normal;
        }
    }
}
.popup-wrap {
    .section-desc {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        left: 0;
        padding: 0 20px;
        line-height: 60px;
        border-top: 1px solid #E1E1E1;
        background: #ffffff;
        z-index: 10;
        img {
            width: 20px;
            margin-right: 8px;
        }
        .left {
            display: flex;
            align-items: center;
        }
        .right-wrap {
            display: flex;
        }
        .right {
            display: flex;
            align-items: center;
            margin-right: 12px;
            &.right-answer {
                color: #2db1ac;
            }
            &.wrong-answer {
                color: #FF1A1A;
            }
            .van-icon{
                color: #000;
            }
        }
    }
    .section-cicle {
        padding: 0 16px;
        .cicle-index {
            display: inline-block;
            width: 40px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #AAAAAA;
            border-radius: 50%;
            margin-right: 8px;
            margin-bottom: 8px;
            text-align: center;
            line-height: 40px;
            color: #666;
            &.active {
                background: #FFA500;
                color: #ffffff;
                border-color: transparent;
            }
        }
        .cicle-correct{
            background: #2db1ac;
        }
        .cicle-error{
            background: #FF0000;
        }
    }
}
.popup-content {
    width: 290px;
    overflow: hidden;
    background: #fff;
    &>.title {
        background: linear-gradient(90deg, #2db1ac, #2db1ac);
        padding: 15px;
        font-size: 18px;
        color: #fff;
        text-align: center;
    }
    .content {
        margin: 20px 0 10px;
        padding: 0 10px;
        font-size: 17px;
        text-align: center;
        .list {
            display: flex;
            align-items: center;
            margin-top: 10px;
            span {
                display: inline-block;
                width: 50%;
            }
        }
    }
    .btn-wrap {
        display: flex;
        padding: 15px;
        .btn {
            width: 40%;
            font-weight: 500;
        }
    }
}
</style>